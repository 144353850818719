import fastdom from 'fastdom'
import { debounce } from 'lodash'
import type {
	ActiveAnchor,
	ActiveAnchorObserverComp,
	Anchor,
	AnchorClient,
	AnchorWithElement,
	PageAnchorsObserverComp,
	PageConfigAnchor,
} from './types'
import { PageAnchorsPageConfig, PageAnchorsSiteConfig } from './types'
import { BrowserWindow, IPropsStore } from '@wix/thunderbolt-symbols'
import {
	RESIZE_DEBOUNCE_WAIT,
	SCROLL_DEBOUNCE_WAIT,
	TOP_ANCHOR,
	TOP_ANCHOR_COMP_ID,
	TOP_ANCHOR_DATA_ID,
	WIX_ADS,
} from './constants'

export const getAnchorsObserversPropsToUpdate = (
	pageAnchorsObservers: Array<PageAnchorsObserverComp>,
	activeAnchorObservers: Array<ActiveAnchorObserverComp>,
	activeAnchor: ActiveAnchor,
	anchorsWithElements: Array<AnchorWithElement>,
	pageId: string,
	shouldSetPageAnchors: boolean
) => {
	const propsToUpdate: {
		[compId: string]: { activeAnchor: ActiveAnchor; anchors?: Array<AnchorClient> }
	} = {}
	const reducedAnchors: Array<AnchorClient> = anchorsWithElements
		? anchorsWithElements.map((anchorWithElement) => ({
				compId: anchorWithElement.compId,
				dataId: anchorWithElement.dataId,
				name: anchorWithElement.name,
		  }))
		: []

	if (activeAnchorObservers.length) {
		activeAnchorObservers.forEach((comp: ActiveAnchorObserverComp) => {
			propsToUpdate[comp.id] = { activeAnchor }
		})
	}

	if (pageAnchorsObservers.length) {
		pageAnchorsObservers.forEach((comp: PageAnchorsObserverComp) => {
			propsToUpdate[comp.id] = { activeAnchor }
			// Set anchors only to VerticalAnchorsMenu component, and only on init
			if (shouldSetPageAnchors && reducedAnchors.length) {
				const { pageTopLabel, hiddenAnchorIds } = comp.compData
				const pageHiddenAnchorIds: Array<string> =
					hiddenAnchorIds && hiddenAnchorIds.hasOwnProperty(pageId)
						? (hiddenAnchorIds as Record<string, Array<string>>)[pageId]
						: []
				const topAnchor: AnchorClient = {
					compId: TOP_ANCHOR_COMP_ID,
					dataId: TOP_ANCHOR_DATA_ID,
					name: pageTopLabel || '',
				}
				propsToUpdate[comp.id].anchors = [topAnchor]
					.concat(reducedAnchors)
					.filter((anchor) => !pageHiddenAnchorIds.includes(anchor.compId))
			}
		})
	}

	return propsToUpdate
}

const convertAnchorToActiveAnchor = (anchor: Anchor): ActiveAnchor => {
	return {
		compId: anchor.compId,
		dataId: anchor.dataId,
	}
}
export const getActiveAnchor = (anchors: Array<Anchor>, offset: number, window: BrowserWindow): ActiveAnchor => {
	const isBottomOfPage = window!.innerHeight + window!.scrollY >= window!.document.body.scrollHeight
	if (isBottomOfPage) {
		return anchors[anchors.length - 1]
	}
	const nextActiveAnchorIndex = anchors.findIndex((anchor: Anchor) => Math.floor(anchor.top - offset) > 0)
	let activeAnchor: ActiveAnchor
	if (nextActiveAnchorIndex === -1 && anchors[anchors.length - 1]) {
		// all anchors above viewport - return last anchor
		activeAnchor = convertAnchorToActiveAnchor(anchors[anchors.length - 1])
	} else if (nextActiveAnchorIndex === 0 || !anchors[nextActiveAnchorIndex - 1]) {
		// active anchor is in the top of the page
		activeAnchor = TOP_ANCHOR
	} else {
		activeAnchor = convertAnchorToActiveAnchor(anchors[nextActiveAnchorIndex - 1])
	}
	return activeAnchor
}

export const createAnchorObserver = (
	pageAnchorsObservers: Array<PageAnchorsObserverComp>,
	activeAnchorObservers: Array<ActiveAnchorObserverComp>,
	pageFeatureConfig: PageAnchorsPageConfig,
	siteFeatureConfig: PageAnchorsSiteConfig,
	window: BrowserWindow,
	propsStore: IPropsStore,
	pageId: string,
	reCheckAnchors: boolean = false
) => {
	const anchors: Array<PageConfigAnchor> = pageFeatureConfig.anchors
	let anchorsWithElements: Array<AnchorWithElement>
	let siteOffset = siteFeatureConfig.siteOffset

	const initAnchors = () => {
		// initialize the wix AD height for anchor calculation
		const wixAds = window!.document.getElementById(WIX_ADS)
		if (wixAds) {
			siteOffset += wixAds.offsetHeight
		}
		// TODO - change this reduce to map once @guybs understands why some elements are missing from the DOM. For now we filter them out
		anchorsWithElements = anchors.reduce(
			(acc: Array<AnchorWithElement>, anchor: PageConfigAnchor): Array<AnchorWithElement> => {
				const element = window!.document.getElementById(anchor.compId)
				if (element) {
					acc.push({
						...anchor,
						element,
						top: typeof anchor.top !== 'undefined' ? anchor.top : element.getBoundingClientRect().top || 0,
					})
				}
				return acc
			},
			[]
		)
		sortAnchors()
	}

	const getMeasuredAnchors = (): Array<AnchorWithElement> => {
		// in the editor anchors are added after we invoke the model update handler, so we need to find all elements on measurement
		if (reCheckAnchors && (!anchorsWithElements || anchors.length > anchorsWithElements.length)) {
			initAnchors()
		}
		return anchorsWithElements.map((anchorWithElement: AnchorWithElement) => ({
			...anchorWithElement,
			top: anchorWithElement.element.getBoundingClientRect().top || 0,
		}))
	}

	const setAnchors = (shouldSetPageAnchors: boolean) => {
		const activeAnchor = getActiveAnchor(anchorsWithElements, siteOffset, window)

		if (activeAnchor) {
			propsStore.update(
				getAnchorsObserversPropsToUpdate(
					pageAnchorsObservers,
					activeAnchorObservers,
					activeAnchor,
					anchorsWithElements,
					pageId,
					shouldSetPageAnchors
				)
			)
		}
	}
	const sortAnchors = () => anchorsWithElements.sort((anchor1, anchor2) => anchor1.top - anchor2.top)

	const measureAnchors = (reSort: boolean) => {
		anchorsWithElements = getMeasuredAnchors()
		if (reSort) {
			sortAnchors()
		}
	}

	const calculateAnchors = (reSort: boolean) => {
		fastdom.measure(() => {
			measureAnchors(reSort)
			setAnchors(false)
		})
	}
	const pageAnchorsScrollListener = debounce(calculateAnchors.bind(null, false), SCROLL_DEBOUNCE_WAIT)
	const pageAnchorsResizeListener = debounce(calculateAnchors.bind(null, true), RESIZE_DEBOUNCE_WAIT)

	return () => {
		fastdom.measure(() => {
			initAnchors()
			setAnchors(true)
		})
		window!.addEventListener('scroll', pageAnchorsScrollListener)
		window!.addEventListener('resize', pageAnchorsResizeListener)

		return () => {
			window!.removeEventListener('scroll', pageAnchorsScrollListener)
			window!.removeEventListener('resize', pageAnchorsResizeListener)
			fastdom.mutate(() => {
				propsStore.update(
					getAnchorsObserversPropsToUpdate(
						pageAnchorsObservers,
						activeAnchorObservers,
						TOP_ANCHOR,
						[],
						'',
						true
					)
				)
			})
		}
	}
}
